import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Button, axios } from 'react-mimg'
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import styles from './navigationBar.module.scss'
import { aboutTabs, managementTabs, operationsTabs, categories, organizationTabs, portfolioTabs } from '../About/AboutConfig';
import { FaUserAlt, FaSignOutAlt, FaSearch } from 'react-icons/fa';
import { NavDropdownItem, NavDropdownItemHeader, NavDropdownTitle, NavLinkItem } from './NavItems'
import { isMobile } from 'react-device-detect';
import LazyLoad from 'react-lazyload'
import logos from '../../images/logos';
import useAuth from '../../hooks/useAuth';

export default function NavigationBar() {
    const [expanded, setExpanded] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userIsAdmin, setUserIsAdmin] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, signOut } = useAuth();


    useEffect(() => {
        setLoading(true);

        isAuthenticated()
            .then(res => {
                setLoggedIn(res.data);
                setLoading(false);

                axios.get('api/login/isAdmin').then((response) => {
                    setUserIsAdmin(response.data);
                })

            })
    }, [location, isAuthenticated])

    if (loading)
        return <div />

    return (
        <Navbar expand="lg" sticky="top" expanded={expanded}>
            <Container>
                <Navbar.Brand className={styles.brand}>
                    <LazyLoad once> <Link to="/"><img src={logos.greenText} alt="Monarch Logo" /></Link></LazyLoad>
                </Navbar.Brand>
                <Navbar.Toggle className={styles.burger} aria-controls="navbar-collapse" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse className={styles.menuDisplay} id="navbar-collapse">
                    <Nav navbarScroll>
                        <NavDropdownTitle title="Properties" active={location.pathname.includes('/properties')}>
                            <div className={styles.propertiesWrapper}>
                                <div>
                                    <NavDropdownItem link="properties" icon={<div className={styles.icon}><FaSearch size="16" /></div>} title="Property Search" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                    <NavDropdown.Divider />
                                </div>
                                <div className={styles.propertiesContainer}>
                                    <div>
                                        <NavDropdownItem link="properties/AR" title="Arkansas" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/AR/17" title="Little Rock" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/CO" title="Colorado" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/CO/1" title="Denver" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/CO/6" title="Gunnison" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/CO/7" title="NO. Colorado" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/CO/2" title="SO. Colorado" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/IA" title="Iowa" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/IA/9" title="Cedar Rapids" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/IA/11" title="Sioux City" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/IL" title="Illinois" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/IL/26" title="Central Illinois" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/IL/9" title="Moline" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/IL/8" title="Waukegan" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/IN" title="Indiana" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/IN/44" title="Clarksville" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/IN/45" title="Evansville" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/IN/56" title="South Bend" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/IN/34" title="Terre Haute" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/KS" title="Kansas" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/KS/21" title="Kansas City" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/KS/52" title="Topeka" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/KS/39" title="Wichita" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/KY" title="Kentucky" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/KY/20" title="Cincinnati Metro" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/KY/37" title="Lexington" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/KY/44" title="Louisville" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/LA" title="Louisiana" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/LA/42" title="Alexandria" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/LA/47" title="Baton Rouge" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/MI" title="Michigan" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/MI/14" title="South East MI" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/MI/30" title="Lansing" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/MI/54" title="Marquette" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/MI/12" title="Western MI" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/MN" title="Minnesota" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/MN/40" title="Duluth" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/MN/16" title="Minneapolis" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/MN/46" title="Rochester" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/MN/51" title="St Cloud" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/MO" title="Missouri" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/MO/21" title="Kansas City" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/MO/22" title="Saint Louis" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/NC" title="North Carolina" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/NC/53" title="Fayetteville" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/NC/50" title="Greenville" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/NC/57" title="Wilmington" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/ND" title="North Dakota" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/ND/62" title="Minot" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/NE" title="Nebraska" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/NE/24" title="Omaha" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/NE/11" title="Sioux City" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/NM" title="New Mexico" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/NM/3" title="Alburquerque" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/NM/49" title="Carlsbad/Hobbs" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/OH" title="Ohio" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/OH/20" title="Cincinnati" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/OH/18" title="Cleveland/Akron" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/OH/13" title="Columbus" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/OH/19" title="Dayton" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/OH/59" title="Lima" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/OH/58" title="Sandusky" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/OH/10" title="Toledo" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/OK" title="Oklahoma" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/OK/5" title="Guymon" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/OK/4" title="Lawton" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/SC" title="South Carolina" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/SC/61" title="Columbia" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/TN" title="Tennessee" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/TN/48" title="Jackson" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/TX" title="Texas" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/TX/35" title="Albilene" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/TX/32" title="Fort Worth" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/TX/55" title="Longview" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/TX/36" title="Lubbock" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/TX/27" title="North Texas" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/TX/33" title="San Angelo" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/TX/28" title="Wichita Falls" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                    <div>
                                        <NavDropdownItem link="properties/WI" title="Wisconsin" bold onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                        <NavDropdownItem link="properties/WI/43" title="Madison" className={styles.hideOnMobile} />
                                        <NavDropdownItem link="properties/WI/23" title="Milwaukee" className={styles.hideOnMobile} />
                                        <NavDropdown.Divider />
                                    </div>
                                </div>
                            </div>
                        </NavDropdownTitle>
                        <div className={styles.showOnMobile}>
                            <NavDropdownTitle title="About" active={location.pathname.includes('/about')}>
                                <NavDropdownItemHeader title={categories.about} />
                                {aboutTabs.allTabs().map(tab => <NavDropdownItem key={tab} link={"about?section=" + categories.about + "&content=" + tab} title={tab} onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />)}
                                <NavDropdownItemHeader title={categories.management} />
                                {managementTabs.allTabs().map(tab => <NavDropdownItem key={tab} link={"about?section=" + categories.management + "&content=" + tab} title={tab} onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />)}
                                <NavDropdownItemHeader title={categories.operations} />
                                {operationsTabs.allTabs().map(tab => <NavDropdownItem key={tab} link={"about?section=" + categories.operations + "&content=" + tab} title={tab} onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />)}
                                <NavDropdownItemHeader title={categories.organization} />
                                {organizationTabs.allTabs().map(tab => <NavDropdownItem key={tab} link={"about?section=" + categories.organization + "&content=" + tab} title={tab} onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />)}
                                <NavDropdownItemHeader title={categories.portfolio} />
                                {portfolioTabs.allTabs().map(tab => <NavDropdownItem key={tab} link={"about?section=" + categories.portfolio + "&content=" + tab} title={tab} onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />)}
                            </NavDropdownTitle>
                        </div>
                        <div className={styles.hideOnMobile}>
                            <NavDropdownTitle title="About" active={location.pathname.includes('/about')}>
                                {categories.allCategories().map(category => <NavDropdownItem key={category} link={"about?section=" + category} title={category} eventKey={category} />)}
                            </NavDropdownTitle>
                        </div>
                        {loggedIn ?
                            <NavDropdownTitle title="Investors" active={location.pathname.includes('/investor')}>
                                <NavDropdownItem link={"Investors/TransactionHistory"} title="Transaction History" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                <NavDropdownItem link={"Investors/MyInvestments"} title="My Investments" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                <NavDropdownItem link={"investors/quarterlyreports"} title="My Quarterly Reports" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                <NavDropdownItem link={"investors/ledger"} title="Owner Ledger" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                <NavDropdownItem link={"investors/reports"} title="Owner Reports" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                <NavDropdownItem link={"Investors/MyK1s"} title="My K1s" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                <NavDropdownItem link={"investors/presentations"} title="Presentations" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                <NavDropdownItem link={"investors/letters"} title="Letters & Schedules" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                <NavDropdownItem link={"investors/ManageMyAccount"} title="Manage My Account" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                                <div> {/*this is to try to get link to open in a new tab, behavior wasn't working as intended with the NavDropdownItem component, so copied the rendered html, and added target="_blank"*/}
                                    <a data-rr-ui-dropdown-item="" className="dropdown-item" href="/Investors/SiteTour" target="_blank">
                                        {/*<div className="navItems_linkWrapper__q6nzr">*/}
                                        {/*<div className="navItems_linkWrapper__-zkvF">*/}
                                        {/*<div className="navItems_linkWrapper__MmTAE">*/}
                                        <div className="navItems_linkWrapper__q6nzr">
                                            View Site Tour Doc
                                        </div>
                                    </a>
                                </div>
                            </NavDropdownTitle> :
                            <NavLinkItem link="login" title="Investors" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                        }
                        <NavLinkItem link="residents" title={isMobile ? "Residents" : "Resident Services"} onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                        <NavDropdownTitle title="Careers" active={location.pathname === '/jobs' || location.pathname === '/careers'}>
                            <NavDropdownItem link="jobs" title="Open Positions" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                            <NavDropdownItem link="careers" title="Careers Home" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                        </NavDropdownTitle>
                        <NavLinkItem link="contact" title="Contact" onClick={() => setTimeout(() => { setExpanded(false) }, 150)} />
                        {userIsAdmin && loggedIn ?
                            <NavDropdownTitle title="Admin" active={location.pathname.includes('/admin')}>
                                <NavDropdownItem link={"Admin/DataSyncing"} title={"Data Syncing Info"} />
                                <NavDropdownItem link={"admin/investorchangeprocessing"} title={"Investor Change Processing"} />
                                <NavDropdownItem link={"admin/paymentprocessingadmin"} title={"Payment Processing"} />
                                <NavDropdownItem link={"admin/addpropertydocument"} title={"Property Document Maintenance"} />
                                <div> {/*the external link wasn't working on this, so I just had to grab this stuff from the rendered HTML on a different component*/}
                                    <a data-rr-ui-dropdown-item="" className="dropdown-item" href="https://mimg.us/v2/admin/propertymediamaint" target="_blank" rel="noreferrer">
                                        {/*<div className="navItems_linkWrapper__MmTAE">*/}
                                        <div className="navItems_linkWrapper__q6nzr">
                                            {/*<div className="navItems_linkWrapper__-zkvF">*/}
                                            Property Media Maintenance
                                        </div>
                                    </a>
                                </div>

                                <NavDropdownItem link={"Admin/SendDocusign"} title={"Send Docusign"} />
                                <NavDropdownItem link={"investors/TransferRequest"} title={"Transfer Request"} />
                                <NavDropdownItem link={"admin/UserMaint"} title={"User Maintenance"} />
                                <NavDropdownItem link={"admin/DocumentViewsReport"} title={"Document Views Report"} />
                                <div> {/*the external link wasn't working on this, so I just had to grab this stuff from the rendered HTML on a different component*/}
                                    <a data-rr-ui-dropdown-item="" className="dropdown-item" href="https://mimg.us/v2/admin/staffmaint" target="_blank" rel="noreferrer">
                                        {/*<div className="navItems_linkWrapper__q6nzr">*/}
                                        {/*<div className="navItems_linkWrapper__MmTAE">*/}
                                        <div className="navItems_linkWrapper__q6nzr">
                                            Website Staffing List Maintenance
                                        </div>
                                    </a>
                                </div>

                            </NavDropdownTitle> : null}
                    </Nav>
                </Navbar.Collapse>
                <div className={styles.signIn}>
                    {loggedIn ? <Button title="Sign Out" red onClick={() => { signOut() }}><FaSignOutAlt /></Button> :
                        <Button title="Sign In" onClick={() => navigate('/login')}><FaUserAlt /></Button>}
                </div>
            </Container>
        </Navbar>
    )
}